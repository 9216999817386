.add_Patient{
    height: 50vh;
    background-color: #fff;
    width: 35% !important;
    bottom: 0 !important;
    left: 0;
    border-radius: 8px 8px 0 0 !important;
    animation: Show 300ms !important;
}
@keyframes Show {
    from {height: 0;}
    to {height: 50vh;}
}
/*  */
/* .add_Patient .react-datepicker-wrapper{
    width: 100%;
} */
.add_Patient .patient_Gender{
    width: 100%;
    font-family: 'tfBold';
    font-size: 18px !important;
    text-align: initial;
}
.add_Patient input{
    font-family: 'tfBold' !important;
    font-size: 18px;
}
.add_Patient .add_Patient_input input{
    text-align: initial !important;

}
@media screen and (max-width: 900px) {
    .add_Patient{
        width: 100% !important;
    }
}