.login .login-logo{
    height: 70px;
    margin-top: 30px;
}
.login .login-card{
    padding: 16px;
    text-align: initial;
    margin: 22px 16px;
    border-radius: 8px;
    box-shadow: 0px 5px 20px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 5px 20px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(194,194,194,1);
}
.login .login-card .login-title{
    font-family:'tfBold';
    font-size: 28px;
    padding: 8px;
    line-height: 30px;
}
.login .login-card .login-input{
    padding: 26px 0;
}
.login .login-card .verify_input input{
    /* padding: 26px 0; */
    text-align: center;
    font-family: 'tfBold';
    font-size: 22px;
    letter-spacing: 12px;
}
.login .login-card .signin-btn{
    background-color: var(--themeColor);
    padding: 12px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    font-family: 'tfMedium';
    cursor: pointer;
}
/*  */
.login .react-datepicker-wrapper{
    width: 100%;
}
.login .profileInput input{
    text-align: initial;
}
.rmdp-container{
    width: 100%;
}