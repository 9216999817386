.appointment{
    font-family: 'tfRegular';
}
.appointment .appointment-card{
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    text-align: initial;
    margin: 0 0 8px;
}
.starts_fill{
    background-color: var(--themeColor);
    color: white;
    width: 50px !important;
    height: 50px !important;
    border-radius: 8px;
    margin: 4px 4px;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 30px !important;
}
.starts{
    background-color: #fff;
    color: #cacaca;
    width: 50px !important;
    height: 50px !important;
    border-radius: 8px;
    font-size: 30px !important;
    margin: 4px 4px;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border:1px solid #cacaca;
}
.recommendView{
    /* position: absolute; */
    /* bottom: 0; */
    width: 35vw;
    min-height: 50vh;
    background-color: #fff;
    color: #4d4d4d;
    border-radius: 8px;
}

@media screen and (max-width: 900px) {
    .recommendView{
        width: 100vw;
    }

}