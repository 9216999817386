body {
  margin: 0;
  font-family: 'tfRegular',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'tfBlack';
  src: url(./fonts/BwSeidoRoundDEMO-Black.otf);
}
@font-face {
  font-family: 'tfBold';
  src: url(./fonts/BwSeidoRoundDEMO-Bold.otf);
}
@font-face {
  font-family: 'tfRegular';
  src: url(./fonts/BwSeidoRoundDEMO-Regular.otf);
}
@font-face {
  font-family: 'tfMedium';
  src: url(./fonts/BwSeidoRoundDEMO-Medium.otf);
}
@font-face {
  font-family: 'tfThin';
  src: url(./fonts/BwSeidoRoundDEMO-Thin.otf);
}
@font-face {
  font-family: 'tfLight';
  src: url(./fonts/BwSeidoRoundDEMO-Light.otf);
}

:root {
  --themeColor:#2CA2BD;
  --themeColorOpacity10:#2CA2BD10;
  --themeColorOpacity30:#2CA2BD30;

  --secondaryColor:#FD7883;
  --secondaryColorOpacity30:#FD788330;
  --thirdColor:#32af32;
  --thirdColorOpacity30:#32af3230;

}