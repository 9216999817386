.add_insurnce{
    position: absolute;
    width: 35%;
    height: 100vh;
    top: 0;
    z-index: 999999;
    background-color: white;
    animation: example 500ms ;
}
@keyframes example {
    from {top:800px;}
    to {top: 0px;}
  }
  .add_insurnce .insu_row{
      padding: 8px 0;
      text-align: initial;
  }
  .add_insurnce .add_head{
      color: var(--themeColor);
      font-size: 16px;
  }
  .add_insurnce .add_input{
      font-family: 'tfBold' !important;
      font-size: 18px;
      /* padding: 4px 0; */
      border-bottom: 1px solid #cacaca;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      min-height: auto !important;
      padding: 8px 2px !important;
      width: 100%;
      text-align: initial;
  }
  .add_insurnce .add_input input{
      text-align: initial;
  }
  .add_insurnce .insurance_card{
      padding: 20%;
      text-align: center;
      margin: 10px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
      -webkit-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
      -moz-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);

  }
  .add_insurnce .react-datepicker-wrapper {
      width: 100%;
  }
.voucher{

}
.voucher .promobox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid #cacaca30;
    padding: 8px;
    
}

.voucher .promobox input{
    text-align: center;
    font-family: 'tfThin';
    font-size: 22px;

}
.voucher .cell{
    padding: 12px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #cacaca30;
    justify-content: space-between;

}
.voucher .cell .logo{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);

}
.redeemView{
    max-width: 300px;
    position: relative;
}
.redeemView input{
    text-align: center !important;
    font-family: 'tfBold' !important;
    letter-spacing: 12px;
    font-size: 22px;

}
.reviews{
    padding: 12px 8px;

}
.reviews .review_card{
    border-radius: 4px;
    padding: 8px;
    margin: 2px 0 12px;
    box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
}
.information input{
    text-align: initial !important;
}
.information .submit{
    position: fixed !important;
    padding: 14px 0 22px;
    background-color: var(--themeColor);
    text-align: center;
    font-size: 22px;
    font-family: 'tfBold';
    width: 35%;
    bottom: 0;
    cursor: pointer;
    color: #fff;
    border-radius: 4px 4px 0 0 ;
    margin: 0;
}
/*  */
.information .react-datepicker-wrapper{
    width: 100%;
}
  @media screen and (max-width: 900px) {
    .add_insurnce{
        width: 100%;
    }
    .redeemView{
        max-width: 80%;
    }
    .information .submit{
        width: 100%;
    }
  }