.facility .views{
    display: flex;
    justify-content: flex-start;
    padding: 0 16px ;
}
.facility .logo{
    width: 80px;
    height: 80px;
    box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
}
.facility .name{
    margin: 0;
    font-family: 'tfBold';
    font-size: 26px;
}
.facility .open{
    padding: 4px 0 0;
}
.facility .slick-arrow{
    display: none !important;
}
.facility .slick-dots{
    position: relative !important;
    bottom: 28px !important;
}
@media screen and (max-width: 900px) {
    .facility .name{
        font-size: 6vw;
    }
  }