.section{
    padding: 16px 16px 8px;
    border-bottom: 1px solid #cacaca;
    text-align: initial;
}
.section .sectionHead{
    font-family: 'tfBold';
    font-size: 22px;
    padding: 8px 0;
}
.section .sectionDesc{
    font-size: 14px;
}
.doctor_actions{
    position: sticky;
    width: 100%;
    display: flex;
    min-height: 80px;
    bottom: 0;
    justify-content: space-around;
    align-items: flex-start;
}
.doctor_actions .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    font-size: 30px;
    margin: 0;
    cursor: pointer;
    box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
}
.announce{
    position: sticky;
    display:flex;
    bottom:150px;
    justify-content: center;
    animation: ShowAnnunce 300ms;

}

@keyframes ShowAnnunce {
    from {opacity: 0;}
    to {opacity: 1;}
}