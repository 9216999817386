.cardDoctors{
    border-radius: 8px;
    /* margin: 12px 0; */
    /* padding: 15% 0; */
    padding: 16px;
    box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    width: 100%;

}
.cardDoctors .doctorInfo .logo{
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    /* margin: 0 12px 0 0; */
    border-radius: 8px;
    object-fit: cover;
}
.cardDoctors .bookBtn{
    background-color: var(--secondaryColorOpacity30);
    padding: 10px;
    margin: 22px 0 0;
    border-radius: 8px;
    color: var(--secondaryColor);
    font-family: 'tfMedium';
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.cardDoctors .callBtn{
    background-color: var(--themeColorOpacity30);
    padding: 10px;
    margin: 22px 0 0;
    border-radius: 8px;
    color: var(--themeColor);
    font-family: 'tfMedium';
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.cardDoctors .doctorInfo{
    display: flex;
    justify-content: space-between;
    align-items: 'center';
}
.cardDoctors .doctorInfo .name{
    font-size: 16px;
    font-family: 'tfMedium';
    color: #4d4d4d;
}
