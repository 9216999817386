.facility .views{
    display: flex;
    justify-content: flex-start;
    padding: 0 ;
}
.facility .logo{
    width: 80px;
    height: 80px;
    object-fit: cover;
    box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(194,194,194,1);
}
.facility .name{
    margin: 0;
    font-family: 'tfBold';
}
.facility .open{
    padding: 4px 0 0;
}
