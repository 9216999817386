.search-view{

}
.popup-modal{
    height: 40vh;
    background-color: #fff;
    width: 35% !important;
    bottom: 0 ;
    left: 0;
    border-radius: 8px 8px 0 0 !important;
    animation: showPopup 200ms !important;
}
@keyframes showPopup {
    from {height: 0}
    to {height: 40vh}
}

.search-view .search-tags{
    display: flex;
    padding: 8px;
    margin: 20px 8px 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
}
.facility-card{
    display: block;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    color: #4d4d4d;

}
.facility-card .logo{
    width: 70px;
    height: 70px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(194,194,194,1);
}
.facility-card .details{
    padding: 0 12px;
    text-align: initial;
    width: 80%;
    
}
.facility-card .details .facility-name{
    font-family: 'tfBold';
    height: 16px;
    color: #4d4d4d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 900px) {
    .popup-modal{
        width: 100% !important;
    }
}