.nearby{
    width: 100%;
}
.nearby .background{
    background-image:  linear-gradient(to bottom, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0), #cacaca);
    width: 100%;
    height: 100%;
    padding-top: 42%;
    border-radius: 8px;
}
.nearby .head{
    display: flex;
    justify-content:space-between;
    padding: 12px;

}
.nearby .head .title{
    font-size: 20px;
    font-family: 'tfBold';

}
.nearby .head .showAll{
    cursor: pointer;
    font-family: 'tfMedium';
    color: var(--themeColor);
}
.nearby .scrollView{
    display: flex;
    overflow: auto;
    width: 100%;
}
.nearby .scrollView .card{
    min-width: 90%;
    padding: 12px;
}
.nearby .scrollView .card .cardHead{
    display: flex;
    justify-content:space-between;
}
.nearby .scrollView .card .cardContent{
    display: flex;
    align-items: flex-end;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 12px 0;
}
.nearby .scrollView .card .cardContent .logo{
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 8px;
    object-fit: contain;
}


.nearby .scrollView .card .cardHead .name{
    font-family: 'tfBold';
    font-size: 16px;
    color: #4d4d4d;
    width: 75%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: initial;

}
.nearby .scrollView .card .cardHead .open{
    font-size: 12px;
    font-weight: 600;
    color: #77C344;

}
